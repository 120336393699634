'use client'

// Next Imports
import dynamic from 'next/dynamic'

// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// Components Imports
import OptionMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/Avatar'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'

// Styled Component Imports
const AppReactApexCharts = dynamic(() => import('@/libs/styles/AppReactApexCharts'))

// Vars
const series = [
    { data: [2000, 2000, 4000, 4000, 3050, 3050, 2050, 2050, 3050, 3050, 4700, 4700, 2750, 2750, 5700, 5700] }
]

const data = [
    {
        title: 'Donates',
        trend: 'negative',
        amount: '$756.26',
        trendDiff: 139.34
    },
    {
        title: 'Podcasts',
        trendDiff: 576.24,
        amount: '$2,207.03'
    }
]

const Sensex = () => {
    // Hooks
    const theme = useTheme()

    // Vars
    const primaryColor = theme.palette.primary.main

    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            zoom: {
                enabled: false
            }
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        stroke: {
            width: 4,
            curve: 'straight'
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         opacityTo: 0,
        //         opacityFrom: 1,
        //         shadeIntensity: 1,
        //         stops: [0, 100],
        //         colorStops: [
        //             [
        //                 {
        //                     offset: 0,
        //                     opacity: 0.4,
        //                     color: primaryColor
        //                 },
        //                 {
        //                     offset: 100,
        //                     opacity: 0.1,
        //                     color: theme.palette.background.paper
        //                 }
        //             ]
        //         ]
        //     }
        // },
        theme: {
            monochrome: {
                enabled: true,
                shadeTo: 'light',
                shadeIntensity: 1,
                color: primaryColor
            }
        },
        grid: {
            show: false,
            padding: {
                top: -42,
                left: 0,
                right: 0,
                bottom: 30
            }
        },
        xaxis: {
            labels: { show: false },
            axisTicks: { show: false },
            axisBorder: { show: false }
        },
        yaxis: { show: false }
    }

    const [indices, setIndices] = useState({});
    const mySubscriber = (msg1, data) => {
        var msg = data.split('|')

        var obj = {
            name: msg[0],
            ltp: msg[1],
            netchangeindi: msg[2],
            pervalue: msg[3],
            close: msg[4],
        }
        // console.log(obj.name);
        if (obj.name == 'SENSEX') {
            setIndices(obj)
        }
    };

    useEffect(() => {
        PubSub.subscribe('Indices', mySubscriber);
    }, []);

    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                avatar={<i className='tabler-list-details text-xl' />}
                title='SENSEX'
                titleTypographyProps={{ variant: 'h5' }}
            // action={
            //     <Typography variant='subtitle2'>As On 11-Jun-2024 03:24 PM</Typography>
            // }
            />
            <CardContent className='flex flex-col gap-6'>
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={indices.pervalue < 0 ? 'error' : indices.pervalue > 0 ? 'primary' : ''}>
                        <i className='tabler-currency-rupee' />
                    </CustomAvatar>
                    <div className='flex justify-between items-center is-full'>
                        <div className='flex flex-col'>
                            <Typography className='font-medium' color={indices.netchangeindi == '+' ? 'success.main' : indices.netchangeindi == '-' ? 'error.main' : ''}>
                                {indices.name == 'SENSEX' ? (indices.ltp / 100).toFixed(2) : '0.00'}
                            </Typography>
                            {/* <Typography variant='body2'>Your Earnings</Typography> */}
                        </div>
                        <Typography className='font-medium' color={indices.pervalue < 0 ? 'error.main' : indices.pervalue > 0 ? 'success.main' : ''}>
                            {isNaN(indices.close) ? '0.00' : (indices.close / 100).toFixed(2)} ({isNaN(indices.pervalue) ? '0' : (indices.pervalue / 100).toFixed(2)}%)
                        </Typography>
                    </div>
                </div>
                <AppReactApexCharts type='area' height={234} series={series} options={options} />
                <div className='flex flex-col gap-4'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='overline'>Advances</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>1550</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='overline'>Declines</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>734</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='overline'>High</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>23389.45</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='overline'>Low</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>23206.65</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='overline'>unchange</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>33</Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
}

export default Sensex
