import { Grid } from "@mui/material"

import Nifty from '@views/main/Nifty'
import Sensex from '@views/main/Sensex'
import LiveIndices from '@views/main/LiveIndices'
import News from '@views/main/News'
import WorldIndices from '@views/main/WorldIndices'
import CorporateAction from '@views/main/CorporateAction'
import FiiDiiActivity from '@views/main/FiiDiiActivity'


const Home = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Nifty />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Sensex />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LiveIndices />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <WorldIndices />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CorporateAction />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <FiiDiiActivity />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
                <News page='home' />
            </Grid>
        </Grid>
    )
}

export default Home
