// import { useDispatch, useSelector } from 'react-redux';
import PubSub from 'pubsub-js'
import _ from 'lodash'
import { Buffer } from 'buffer';
import { LTP_SOCKET_URL } from '@/views/common/commonUrl'

const { StringDecoder } = require('string_decoder');
const decoder = new StringDecoder('utf8');

export const join = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { join: token, exch: type }
        ));
    }
}

export const remove = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { remove: token, exch: type })
        );
    }
}

export const joinBest5 = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { joinBest5: token, exch: type }
        ));
    }
}

export const removeBest5 = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { removeBest5: token, exch: type })
        );
    }
}

export const joinLTP = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { joinLTP: token, exch: type }
        ));
    }
}

export const removeLTP = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { removeLTP: token, exch: type })
        );
    }
}

export const joinLTPChange = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { joinLTPChange: token, exch: type }
        ));
    }
}

export const removeLTPChange = (token, type) => {
    if (global.ws && global.ws.readyState == WebSocket.OPEN) {
        global.ws.send(JSON.stringify(
            { removeLTPChange: token, exch: type })
        );
    }
}

export const connect = (accessToken) => {

    //ws://14.195.72.131:9998
    // ws://14.195.72.131:9998
    global.ws = new WebSocket(LTP_SOCKET_URL, ["access_token", accessToken]);
    global.ws.binaryType = 'arraybuffer';

    global.ws.onopen = () => {
        console.log('LTP Socket Connected');
    };

    global.ws.onmessage = (message) => {
        var msg = decoder.write(Buffer.from(message.data));
        var SData = msg.split('|')


        if (SData[0] == 'index') {
            PubSub.publish('Indices', _.replace(msg, 'index|', ''));
        } else if (SData[0] == 'cash') {
            PubSub.publish(SData[19].toString(), _.replace(msg, 'cash|', ''));
            // var msgdata  =  _.replace(msg, 'cash|', '').split('|');
        } else if (SData[0] == 'fno') {
            PubSub.publish(SData[19].toString(), _.replace(msg, 'fno|', ''));
        } else if (SData[0] == 'cashbest5') {
            PubSub.publish(SData[1] + 'Best5', _.replace(msg, 'cashbest5|', ''));
        } else if (SData[0] == 'fnobest5') {
            PubSub.publish(SData[1] + 'Best5', _.replace(msg, 'fnobest5|', ''));

            global.myCache.set(SData[1].toString(), _.replace(msg, 'fnobest5|', ''), 0)
        } else if (SData[0] == 'cashltp') {
            PubSub.publish(SData[1] + 'LTP', _.replace(msg, 'cashltp|', ''));
        } else if (SData[0] == 'fnoltp') {
            PubSub.publish(SData[1] + 'LTP', _.replace(msg, 'fnoltp|', ''));
        } else if (SData[0] == 'bsecash') {
            PubSub.publish(SData[1].toString(), _.replace(msg, 'bsecash|', ''));
        } else if (SData[0] == 'bsefo') {
            // console.log(SData[0])
            PubSub.publish(SData[1].toString(), _.replace(msg, 'bsefo|', ''));
        }
    };

    global.ws.onclose = () => {
        global.ws = null
    };
}

