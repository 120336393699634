import CustomAvatar from "@/@core/components/mui/Avatar"
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, LinearProgress, MenuItem, Select, Stack, Typography } from "@mui/material"
import classNames from "classnames"
import { useEffect, useState } from "react"

import { ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'

const axios = require('axios');
const _ = require('lodash')

const Volume = () => {
    const [display, setDisplay] = useState('NSE')

    const [isLoader, setIsLoader] = useState(false)
    const [volumeData, setVolumeData] = useState()
    useEffect(() => {
        if (_.isEmpty(volumeData)) {
            fetchData('NSE')
        }
    }, [])

    const fetchData = async (exchange) => {
        setIsLoader(true)
        setDisplay(exchange)
        var data = {
            "exchange": exchange,
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: APIURL + 'getVolumeToppers',
            headers: {},
            data: data
        };

        await global.axios.request(config)
            .then(async (response) => {
                var finalArr = []
                if (response.data) {
                    if (response.data.record && response.data.record.length > 0) {
                        for await (const ele of response.data.record) {
                            finalArr.push({
                                title: ele.SYMBOL == "" ? ele.S_NAME : ele.SYMBOL,
                                amount: ele.Price,
                                chng: ele.Change,
                                perchng: ele.PerChange,
                                value: ele.Volume,
                                avatarColor: 'warning',
                                icon: 'tabler-chart-histogram'
                            })
                        }
                    }
                }
                setVolumeData(finalArr)
                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                avatar={<i className='tabler-list-details text-xl' />}
                title='Volume'
                titleTypographyProps={{ variant: 'h5' }}
                action={
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={display}
                        onChange={(e) => fetchData(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={'NSE'}>NSE</MenuItem>
                        <MenuItem value={'BSE'}>BSE</MenuItem>
                    </Select>
                }
            />
            <CardContent className='flex flex-col gap-[1.5625rem]' sx={{ overflow: 'auto', maxHeight: '400px' }}>
                {isLoader == false ? volumeData && volumeData.map((item, index) => (
                    <div key={index} className='flex items-center gap-4'>
                        <CustomAvatar skin='light' variant='rounded' color={item.avatarColor} size={34}>
                            <i className={classNames(item.icon, 'text-[22px]')} />
                        </CustomAvatar>
                        <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                            <div className='flex flex-col'>
                                <Typography className='font-medium' color='text.primary'>
                                    {item.title}
                                </Typography>
                                <Typography variant='body2'>
                                    {item.value}
                                </Typography>
                            </div>
                            <div className='flex items-center gap-4'>
                                <Typography>{item.amount}</Typography>
                                <Typography
                                    className='flex justify-end is-11'
                                // color={'success.main'}
                                >
                                    {item.chng}
                                </Typography>
                                <Typography
                                    className='flex justify-end is-11'
                                    color={'success.main'}
                                    variant="caption"
                                >
                                    {item.perchng}%
                                </Typography>
                            </div>
                        </div>
                    </div>
                )) : <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <CircularProgress />
                    <Typography variant='caption' component='div'>Please wait...</Typography>
                </Stack>}
            </CardContent>
        </Card>
    )
}

export default Volume
