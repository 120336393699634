// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

// Third-party Imports
import classnames from 'classnames'

// Components Imports
import OptionMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/Avatar'
import { useEffect, useState } from 'react'

import { ACCORD_WORLD_INDICES_URL, ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'
import moment from 'moment'
import { CircularProgress, Stack } from '@mui/material'
const axios = require('axios');
const _ = require('lodash')

// Vars
const data = [
    {
        title: 'Direct Source',
        subtitle: 'Direct link click',
        amount: '1.2k',
        trendNumber: 4.2,
        icon: 'tabler-shadow'
    },
    {
        title: 'Social Networks',
        subtitle: 'Social Channels',
        amount: '31.5k',
        trendNumber: 8.2,
        icon: 'tabler-globe'
    },
    {
        title: 'Email Newsletter',
        subtitle: 'Mail Campaigns',
        amount: '893',
        trendNumber: 2.4,
        icon: 'tabler-mail'
    },
    {
        title: 'Referrals',
        subtitle: 'Impact Radius Visits',
        amount: '342',
        trendNumber: 0.4,
        trend: 'negative',
        icon: 'tabler-external-link'
    },
    {
        title: 'ADVT',
        subtitle: 'Google ADVT',
        amount: '2.15k',
        trendNumber: 9.1,
        icon: 'tabler-ad'
    },
    {
        title: 'Other',
        subtitle: 'Many Sources',
        amount: '12.5k',
        trendNumber: 6.2,
        icon: 'tabler-star'
    }
]

const CorporateAction = () => {
    const [isLoader, setIsLoader] = useState(false)
    const [actionData, setActionData] = useState()
    useEffect(() => {
        // console.log(moment("18 Jun 2024", 'DD MMM YYYY').format("YYYY-MM-DD"));
        const fetchData = async () => {
            setIsLoader(true)
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: APIURL + 'getCorporateActions',
                headers: {}
            };

            await global.axios.request(config)
                .then(async (response) => {
                    setActionData(response.data.record)
                    setIsLoader(false)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (_.isEmpty(actionData)) {
            fetchData()
        }
    }, [])


    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title='Corporate Action'
            // subheader='38.4k Visitors'
            // action={<OptionMenu options={['Last Week', 'Last Month', 'Last Year']} />}
            />
            <CardContent className='flex flex-col gap-4' sx={{ overflow: 'auto', maxHeight: '400px' }}>
                {isLoader == false ? actionData && actionData.map((item, index) => (
                    <div key={index} className='flex items-center gap-4'>
                        <CustomAvatar skin='light' variant='rounded' size={34}>
                            <i className={classnames(item.icon, 'text-[22px] text-textSecondary')} />
                        </CustomAvatar>
                        <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                            <div className='flex flex-col'>
                                <Typography className='font-medium' color='text.primary'>
                                    {item.compname}
                                </Typography>
                                <Typography variant='body2'>
                                    {item.action} | {item.date}
                                </Typography>
                            </div>
                            <div className='flex items-center gap-4'>
                                {/* <Typography>{item.amount}</Typography> */}
                                <Chip
                                    variant='tonal'
                                    size='small'
                                    color={item.action == 'Split' ? 'info' : item.action == 'Bonus' ? 'warning' : item.action == 'Rights' ? 'secondary' : 'success'}
                                    label={`${item.amount}`}
                                />
                            </div>
                        </div>
                    </div>
                )) : <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <CircularProgress />
                    <Typography variant='caption' component='div'>Please wait...</Typography>
                </Stack>}


            </CardContent>
        </Card>
    )
}

export default CorporateAction
