import { Grid } from "@mui/material"

import IpoAnalysis from '@views/main/IpoAnalysis'
import IpoLimelight from '@views/main/IpoLimelight'
import IpoIssues from '@views/main/IpoIssues'
import IpoNewListing from '@views/main/IpoNewListing'
import Volume from '@views/main/Volume'
import Value from '@views/main/Value'
import News from '@views/main/News'

const IPO = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <IpoIssues />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <IpoNewListing />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <IpoLimelight />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <IpoAnalysis />
                    </Grid> */}

                    {/* <Grid item xs={12} sm={4}>
                        <Gainer />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Volume />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Value />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Looser />
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <News page='ipo' />
            </Grid>
        </Grid>

    )
}

export default IPO
