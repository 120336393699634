import CustomAvatar from "@/@core/components/mui/Avatar"
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, LinearProgress, MenuItem, Select, Stack, Typography } from "@mui/material"
import classNames from "classnames"
import { useEffect, useState } from "react"

import { ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'

const axios = require('axios');
const _ = require('lodash')

const Looser = () => {
    const [display, setDisplay] = useState('NSE')

    const [isLoader, setIsLoader] = useState(false)
    const [looserData, setLooserData] = useState()
    useEffect(() => {
        if (_.isEmpty(looserData)) {
            fetchData('NSE')
        }
    }, [])

    const fetchData = async (exchange) => {
        setIsLoader(true)
        setDisplay(exchange)
        var data = {
            "exchange": exchange,
            "type": 'Lose'
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: APIURL + 'getGainersLosers',
            headers: {},
            data: data
        };

        await global.axios.request(config)
            .then(async (response) => {
                if (response.data) {
                    setLooserData(response.data.record)
                }

                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <Typography variant="h5" >
                                    Looser
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={display}
                                    onChange={(e) => fetchData(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value={'NSE'}>NSE</MenuItem>
                                    <MenuItem value={'BSE'}>BSE</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ position: "relative" }}>
                                    <LinearProgress
                                        value={20}
                                        variant='determinate'
                                        color={"error"}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                }
            />
            <CardContent className='flex flex-col gap-[1.5625rem]' sx={{ overflow: 'auto', maxHeight: '400px' }}>
                {isLoader == false ? looserData && looserData.map((item, index) => (
                    <div key={index} className='flex items-center gap-4'>
                        <CustomAvatar skin='light' variant='rounded' color={item.avatarColor} size={34}>
                            <i className={classNames(item.icon, 'text-[22px]')} />
                        </CustomAvatar>
                        <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                            <Typography className='font-medium' color='text.primary'>
                                {item.title}
                            </Typography>
                            <div className='flex items-center gap-4'>
                                <Typography>{item.amount}</Typography>
                                <Typography
                                    className='flex justify-end is-11'
                                // color={'success.main'}
                                >
                                    {item.chng}
                                </Typography>
                                <Typography
                                    className='flex justify-end is-11'
                                    color={'error.main'}
                                    variant="caption"
                                >
                                    {item.perchng}%
                                </Typography>
                            </div>
                        </div>
                    </div>
                )) : <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <CircularProgress />
                    <Typography variant='caption' component='div'>Please wait...</Typography>
                </Stack>}
            </CardContent>
        </Card>
    )
}

export default Looser
