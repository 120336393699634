// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Third-party Imports
import classnames from 'classnames'

// Components Imports
import OptionMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/Avatar'
import { useEffect, useState } from 'react'

import { APIURL } from '@/views/common/commonUrl'

const axios = require('axios');
const _ = require('lodash')

const WorldIndices = () => {
    const [worldData, setWorldData] = useState()
    useEffect(() => {
        const fetchData = async () => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: APIURL + 'getWorldIndices',
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Access-Control-Allow-Origin": "*",
                }
            };

            await global.axios.request(config)
                .then((response) => {
                    if (response.data) {

                        var finalArr = []
                        response.data.record.forEach((element) => {
                            var cntName = _.replace(element.COUNTRY.toLowerCase().trim(), " ", '')
                            finalArr.push({
                                title: element.INDICES,
                                amount: parseFloat(element.PRICE).toFixed(2),
                                trendNumber: element.perChg,
                                avatarColor: element.perChg < 0 ? 'error' : 'success',
                                icon: 'tabler-ban',
                                imgSrc: '/images/flags/' + cntName + '.png',
                                subtitle: element.COUNTRY
                            })
                        })
                        setWorldData(finalArr)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (_.isEmpty(worldData)) {
            fetchData()
        }
    }, [])

    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                avatar={<i className='tabler-list-details text-xl' />}
                title='World Indices'
                titleTypographyProps={{ variant: 'h5' }}
            // subheader='8.52k Social Visitors'
            // action={<OptionMenu options={['Last Month', 'Last 6 Months', 'Last Year']} />}
            />
            <CardContent className='flex flex-col gap-[1.5625rem]' sx={{ overflow: 'auto', maxHeight: '400px' }}>
                {worldData && worldData.map((item, index) => (
                    <div key={index} className='flex items-center gap-4'>
                        <img src={item.imgSrc} alt={item.subtitle} width={34} />
                        {/* <CustomAvatar skin='light' variant='rounded' color={item.avatarColor} size={34}>
                            <i className={classnames(item.icon, 'text-[22px]')} />
                        </CustomAvatar> */}
                        <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                            {/* <Typography className='font-medium' color='text.primary'>
                                {item.title}
                            </Typography> */}
                            <div className='flex flex-col'>
                                <Typography className='font-medium' color='text.primary'>
                                    {item.title}
                                </Typography>
                                <Typography variant='body2'>{item.subtitle}</Typography>
                            </div>
                            <div className='flex items-center gap-4'>
                                <Typography>{item.amount}</Typography>
                                <Typography
                                    className='flex justify-end is-11'
                                    color={`${item.avatarColor}.main`}
                                >
                                    {item.trendNumber}
                                </Typography>
                            </div>
                        </div>
                    </div>
                ))}
            </CardContent>
        </Card>
    )
}

export default WorldIndices
