const crypto = require('crypto');

export const APIURL = 'https://services.kalpvruks.com/' // https://services.kalpvruks.com/      http://192.168.2.79:8765/

export const ACCORD_TOKEN = 'T8vjln1n2StO_BiPNwOaLoq2mXONSWTZ'

export const ACCORD_NEWAPI_URL = 'http://news.accordwebservices.com/'

export const ACCORD_WORLD_INDICES_URL = 'http://stock.accordwebservices.com/Stock/GetWorldIndices'

export const LTP_SOCKET_URL = 'wss://wss.kalpvruks.com'         //ws://14.195.72.131:9998   // ws://43.228.99.123:9998      // wss://wss.kalpvruks.com

export const SOCKET_CONNECT_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4MTI1OTA3NzEsImRhdGEiOnsiQ0xJRU5USUQiOiIxQUcyNyIsIkFUeXBlIjoiQ0xJRU5UIiwiaXAiOiIifSwiaWF0IjoxNjkyNTkwODMxfQ.nqmaaQoWEtus3M4HBiE916k4xXCVJ8--MRPT_DuTI5I'

export const BOSS_APIURL = 'http://43.228.99.124:15000/'

export const BO_APIURL = 'http://192.168.2.16:7002/'

export function getCookie() {
    try {
        if (typeof document !== 'undefined' && typeof window !== 'undefined') {
            const cookies = typeof document !== 'undefined' && typeof window !== 'undefined' && document.cookie.split('; ');
            const cookieMap = {};
            cookies.forEach(cookie => {
                const [name, value] = cookie.split('=');
                cookieMap[name] = value;
            });
            if (cookieMap["KalpUser"]) {
                return cookieMap
            } else {
                document.cookie = 'KalpUser=; Max-Age=-99999999;';
                window.location = "/login";
            }
        } else {
            document.cookie = 'KalpUser=; Max-Age=-99999999;';
            window.location = "/login";
        }
    } catch (error) {
        console.log(error)
    }
}

export function formatMoney(number) {
    let format = new Intl.NumberFormat('en-IN', {
        // style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });
    return format.format(number);
}

export function formatMoneyExpense(number) {
    let format = new Intl.NumberFormat('en-IN', {
        // style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
    });
    return format.format(parseInt(number));
}

export function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(2)} Lac`
    return formatMoney(value);
}

export const UserLogout = async () => {
    // Redirect to login page
    // updateCookieKalpUser('')
    document.cookie = 'KalpUser=; Max-Age=-99999999;';
    window.location = "/login";
    // router.push('/login?x=x')
}

export function EncryptedValue(value) {
    var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
    var key = 'e59c151f-5c8c-4654-a7d4-c291185e2c59';
    var cipher = crypto.createCipher(algorithm, key);
    var encrypted = cipher.update(value, 'utf8', 'hex') + cipher.final('hex');
    return encrypted;
}
