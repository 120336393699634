'use client'

// Next Imports
import Link from 'next/link'
import Logo from '@components/layout/shared/Logo'

// MUI Imports
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// Hook Imports
import { useImageVariant } from '@core/hooks/useImageVariant'
import { Box, Button, Grid, Tab } from '@mui/material'

import '@views/main/style.css'
import { useEffect, useState } from 'react'

import Home from '@views/main/Home'
import Equity from '@views/main/Equity'
import Derivatives from '@views/main/Derivatives'
import IPO from '@views/main/Ipo'
import { SOCKET_CONNECT_TOKEN } from '@views/common/commonUrl'
import { connect } from '@/utils/Socket'
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import ModeDropdown from '@components/layout/shared/ModeDropdown'

const instance = Axios.create();
global.axios = setupCache(instance);


import CustomAvatar from '@/@core/components/mui/Avatar'

// Styled Components
const MaskImg = styled('img')({
    blockSize: 'auto',
    maxBlockSize: 355,
    inlineSize: '100%',
    position: 'absolute',
    insetBlockEnd: 0,
    zIndex: -1
})

const Main = ({ mode }) => {

    // Vars
    const darkImg = '/images/pages/misc-mask-dark.png'
    const lightImg = '/images/pages/misc-mask-light.png'

    // Hooks
    const theme = useTheme()
    const hidden = useMediaQuery(theme.breakpoints.down('md'))
    const miscBackground = useImageVariant(mode, lightImg, darkImg)

    const [value, setValue] = useState('home')

    useEffect(() => {
        connect(SOCKET_CONNECT_TOKEN)
    }, [])


    const handleClick = (newValue) => {
        if (newValue == 'backoffice') {

        } else {
            setValue(newValue)
        }

    }



    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12 / 9}>
                    <ModeDropdown />
                </Grid>
                <Grid item xs={12 / 9}></Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer' onClick={() => handleClick('home')}>
                    <Typography color={value == '' || value == 'home' ? 'primary' : 'secondary'}><i className='tabler-home' fontSize={'14px'} /></Typography>
                    <Typography color={value == '' || value == 'home' ? 'primary' : 'secondary'} variant='overline' fontSize={'14px'} fontWeight={'600'}>Home</Typography>
                </Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer' onClick={() => handleClick('equity')}>
                    <Typography color={value == 'equity' ? 'primary' : 'secondary'}><i className='tabler-briefcase' fontSize={'14px'} /></Typography>
                    <Typography color={value == 'equity' ? 'primary' : 'secondary'} variant='overline' fontSize={'14px'} fontWeight={'600'}>Equity</Typography>
                </Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer' onClick={() => handleClick('home')}>
                    <Logo />
                </Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer' onClick={() => handleClick('derivative')}>
                    <Typography color={value == 'derivative' ? 'primary' : 'secondary'}><i className='tabler-brand-databricks' fontSize={'14px'} /></Typography>
                    <Typography color={value == 'derivative' ? 'primary' : 'secondary'} variant='overline' fontSize={'14px'} fontWeight={'600'}>Derivatives</Typography>
                </Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer' onClick={() => handleClick('ipo')}>
                    <Typography color={value == 'ipo' ? 'primary' : 'secondary'}><i className='tabler-chart-area-line' fontSize={'14px'} /></Typography>
                    <Typography color={value == 'ipo' ? 'primary' : 'secondary'} variant='overline' fontSize={'14px'} fontWeight={'600'}>IPO</Typography>
                </Grid>
                <Grid item xs={12 / 9}></Grid>
                <Grid item xs={12 / 9} textAlign={'center'} className='logo pointer'>
                    <Button href='/login' startIcon={<i className='tabler-login-2' fontSize={'20px'} />}>
                        <Typography color={'secondary'} variant='overline' fontSize={'14px'} fontWeight={'600'}>Back Office</Typography>
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} className='logo'>
                    {value == '' || value == 'home' && <Home />}
                    {value == 'equity' && <Equity />}
                    {value == 'derivative' && <Derivatives />}
                    {value == 'ipo' && <IPO />}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Main
