import { Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, MenuItem, Select, Stack, Typography } from "@mui/material"
// Style Imports
import styles from '@core/styles/table.module.css'
import { useEffect, useState } from "react"

import { ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'
import moment from "moment";

const axios = require('axios');
const _ = require('lodash')

const IpoIssues = () => {
    const [display, setDisplay] = useState('IPO_GET_CLOSEDISSUE')

    const [isLoader, setIsLoader] = useState(false)
    const [ipoIssuesData, setIpoIssuesData] = useState()
    const [ipoDetailData, setIpoDetailData] = useState()
    const [open, setOpen] = useState(false)


    useEffect(() => {
        if (_.isEmpty(ipoIssuesData)) {
            fetchData('IPO_GET_CLOSEDISSUE')
        }
    }, [])

    const fetchData = async (type) => {
        setIsLoader(true)
        setDisplay(type)
        var data = {
            "type": type
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: APIURL + 'getIPOIssues',
            headers: {},
            data: data
        };

        await global.axios.request(config)
            .then(async (response) => {

                if (response.data) {
                    setIpoIssuesData(response.data.record)
                }

                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleClickOpen = async (fincode) => {
        var data = {
            "fincode": fincode
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: APIURL + 'getIPOSnapshotDetails',
            headers: {},
            data: data
        };

        await global.axios.request(config)
            .then(async (response) => {
                if (response.data) {
                    setIpoDetailData(response.data.record)
                    setOpen(true)
                }

            })
            .catch((error) => {
                console.log(error);
            });


        /* let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://company.accordwebservices.com/Company/ShowCompanyBackground?FinCode=' + fincode + '&token=' + ACCORD_TOKEN,
            headers: {}
        };

        await global.axios.request(config)
            .then(async (response) => {
                if (response.data) {
                    var finalArr = {}
                    finalArr['companyDetails'] = response.data
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: 'http://ipo.accordwebservices.com/IPO/GetIPOSnapshotDetails?FinCode=' + fincode + '&token=' + ACCORD_TOKEN,
                        headers: {}
                    };

                    await global.axios.request(config)
                        .then(async (response) => {
                            if (response.data) {
                                finalArr['companySnap'] = response.data
                            }
                            setOpen(true)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(finalArr);
                    setIpoDetailData(finalArr)
                }
                setOpen(true)
            })
            .catch((error) => {
                console.log(error);
            }); */

    }
    const handleClose = () => setOpen(false)

    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={"IPO ISSUES"}
                action={
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={display}
                        onChange={(e) => fetchData(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={'IPO_GET_CURRENTISSUE'}>Current</MenuItem>
                        <MenuItem value={'IPO_GET_FORTHCOMINGISSUE'}>Fourth Coming</MenuItem>
                        <MenuItem value={'IPO_GET_CLOSEDISSUE'}>Closed</MenuItem>
                    </Select>
                }
            />
            <CardContent className="ipo">
                <table width='100%' className={styles.table}>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Close Date</th>
                            <th style={{ textAlign: 'right' }}>Price Band</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader == false ? ipoIssuesData && ipoIssuesData.map((item, index) => (
                            <tr>
                                <td onClick={() => handleClickOpen(item.fincode)} style={{ cursor: 'pointer' }}>{item.title}</td>
                                <td>{item.date}</td>
                                <td style={{ textAlign: 'right' }}>{item.price}</td>
                            </tr>
                        )) : <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <CircularProgress />
                            <Typography variant='caption' component='div'>Please wait...</Typography>
                        </Stack>}
                    </tbody>
                </table>
                {ipoDetailData && <Dialog
                    maxWidth={'lg'}
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby='customized-dialog-title'
                    open={open}
                    sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
                >
                    <DialogTitle id='customized-dialog-title'>
                        <Typography variant='h5' component='span'>
                            {ipoDetailData.Table[0].COMPNAME}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title='ADDRESS'
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent className='flex flex-col gap-4'>
                                        <Typography>
                                            {ipoDetailData.Table[0].ADDRESS}
                                        </Typography>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">City : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].CITY}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">State : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].STATE}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">Pincode : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].PINCODE}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">Phone : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].PHONE}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">Email : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].E_MAIL}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">Website : </Typography>
                                            </div>
                                            <Typography color='text.secondary'>{ipoDetailData.Table[0].WEBSITE}</Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title='IPO DETAILS'
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent className='flex flex-col gap-4'>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Issue Open Date
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography>
                                                        {moment(ipoDetailData.Table1[0].OPENDATE, "M/D/YYYY HH:mm:ss A").format("DD-MM-YYYY")}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Issue Close Date
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>
                                                        {moment(ipoDetailData.Table1[0].CLOSEDATE, "M/D/YYYY HH:mm:ss A").format("DD-MM-YYYY")}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Application Money
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].APLN_MONEY}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Allotment Money
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].ALOT_MONEY}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Price Band
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].Issueprice1} - {ipoDetailData.Table1[0].issueprice2}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Minimum Bid Quantity
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].Minapln}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Issue Size (Shares)
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].Total_Equity}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Market Lot
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].MktLot}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title='BUSINESS DESCRIPTION'
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent sx={{ overflow: 'auto', maxHeight: '300px' }}>
                                        <Typography color='text.secondary' textAlign={'justify'}>
                                            {ipoDetailData.Table1[0].Description}
                                        </Typography>
                                        {/* <Divider className='mbs-7 mbe-7' />
                                        <Grid container>
                                            <Grid item xs={12} sm={6} className='flex flex-col pie-5 gap-[26px]'>
                                                <div className='flex items-center gap-2.5'>
                                                    <div className='flex'>
                                                        <i className='tabler-lock-open text-xl text-textSecondary' />
                                                    </div>
                                                    <Typography color='text.secondary'>Full Access</Typography>
                                                </div>
                                                <div className='flex items-center gap-2.5'>
                                                    <div className='flex'>
                                                        <i className='tabler-user text-xl text-textSecondary' />
                                                    </div>
                                                    <Typography color='text.secondary'>15 Members</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} className='flex flex-col max-sm:mbs-[26px] sm:pis-5 sm:border-is gap-[26px]'>
                                                <div className='flex items-center gap-2.5'>
                                                    <div className='flex'>
                                                        <i className='tabler-lock-open text-xl text-textSecondary' />
                                                    </div>
                                                    <Typography color='text.secondary'>Access all Features</Typography>
                                                </div>
                                                <div className='flex items-center gap-2.5'>
                                                    <div className='flex'>
                                                        <i className='tabler-user text-xl text-textSecondary' />
                                                    </div>
                                                    <Typography color='text.secondary'>Lifetime Free Update</Typography>
                                                </div>
                                            </Grid>
                                        </Grid> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} >
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title='OBJECTIVE'
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent sx={{ overflow: 'auto', maxHeight: '300px' }}>
                                        {ipoDetailData.Table1[0].Objective.split(/\d+\.(?= )/).length > 1 ? ipoDetailData.Table1[0].Objective.split(/\d+\.(?= )/).map((item, index) => (
                                            index > 0 && <div className='flex items-center gap-2.5'>
                                                <div className='flex'>
                                                    <i className='tabler-arrow-badge-right-filled text-xl text-textSecondary' />
                                                </div>
                                                <Typography color='text.secondary' textAlign={'justify'}>
                                                    {item}
                                                </Typography>
                                            </div>
                                        )) :
                                            <Typography color='text.secondary' textAlign={'justify'}>{ipoDetailData.Table1[0].Objective}</Typography>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title="PROMOTER'S HOLDING"
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent className='flex flex-col gap-4'>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Total Share Capital
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography>
                                                        {ipoDetailData.Table1[0].pre_share_cap}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Offered to Public
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>
                                                        {ipoDetailData.Table1[0].Offer_to_public}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Promoter's Holding (Pre-Issue)
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].pre_promoterholder}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                                                <div className='flex flex-col'>
                                                    <Typography color='text.primary' variant='caption'>
                                                        Promoter's Holding (Post-Issue)
                                                    </Typography>
                                                </div>
                                                <div className='flex items-center gap-4'>
                                                    <Typography className='font-medium'>{ipoDetailData.Table1[0].post_promoterhold}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title="REGISTRAR"
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent className='flex flex-col gap-4'>
                                        <Typography>
                                            {ipoDetailData.Table2[0].REGISTRAR}
                                        </Typography>
                                        <div className='flex items-center gap-2.5'>
                                            <Typography variant="caption" color='text.secondary'>{ipoDetailData.Table2[0].ADDRESS}</Typography>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <div className='flex'>
                                                <Typography variant="overline">Listing : </Typography>
                                            </div>
                                            <Typography variant="caption" color='text.secondary'>{ipoDetailData.Table2[0].LISTING}</Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ height: '100%' }}>
                                    <CardHeader
                                        avatar={<i className='tabler-list-details text-xl' />}
                                        title="LEAD MANAGER"
                                        titleTypographyProps={{ variant: 'h6' }}
                                    />
                                    <CardContent className='flex flex-col gap-4'>
                                        {ipoDetailData.Table1[0].LEADMANAGER.split(",").map((item, index) => (
                                            <div className='flex items-center gap-2.5'>
                                                <Typography variant="caption" color='text.secondary'>{item}</Typography>
                                            </div>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>}
            </CardContent>
        </Card>
    )
}

export default IpoIssues
