// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Third-party Imports
import classnames from 'classnames'

// Components Imports
import OptionMenu from '@core/components/option-menu'
import CustomAvatar from '@core/components/mui/Avatar'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

// Vars

const LiveIndices = () => {
    const [indices, SetIndices] = useState({
        nifty: {},
        banknifty: {},
        indiavix: {},
        sensex: {},
        niftyfin: {},
        niftymid: {},
        bankex: {},
    })
    const { nifty, banknifty, indiavix, sensex, niftyfin, niftymid, bankex } = indices


    useEffect(() => {
        PubSub.subscribe('Indices', mySubscriber);
    }, [])

    const mySubscriber = (msg1, data) => {
        var msg = data.split('|')

        var obj = {
            name: msg[0],
            ltp: msg[1],
            netchangeindi: msg[2],
            pervalue: msg[3],
            close: msg[4],
        }
        if (obj.name == 'Nifty 50') {
            SetIndices(prevState => ({ ...prevState, nifty: obj }))
        } else if (obj.name == 'Nifty Bank') {
            SetIndices(prevState => ({ ...prevState, banknifty: obj }))
        } else if (obj.name == 'India VIX') {
            SetIndices(prevState => ({ ...prevState, indiavix: obj }))
        } else if (obj.name == 'SENSEX') {
            SetIndices(prevState => ({ ...prevState, sensex: obj }))
        } else if (obj.name == 'Nifty Fin Service') {
            SetIndices(prevState => ({ ...prevState, niftyfin: obj }))
        } else if (obj.name == 'NIFTY MID SELECT') {
            SetIndices(prevState => ({ ...prevState, niftymid: obj }))
        } else if (obj.name == 'BANKEX') {
            SetIndices(prevState => ({ ...prevState, bankex: obj }))
        }
    };



    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                avatar={<i className='tabler-list-details text-xl' />}
                title='Indices'
                titleTypographyProps={{ variant: 'h5' }}
            />
            <CardContent className='flex flex-col gap-[1.5625rem]'>
                {/* NIFTY 50 */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'success'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-n', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    NIFTY 50
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${nifty.netchangeindi == '+' ? 'success.main' : nifty.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {nifty.name == 'Nifty 50' ? (nifty.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${nifty.pervalue < 0 ? 'error.main' : nifty.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(nifty.close) ? '0.00' : (nifty.close / 100).toFixed(2)} ({isNaN(nifty.pervalue) ? '0' : (nifty.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* BANKNIFTY */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'info'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-b', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    BANKNIFTY
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${banknifty.netchangeindi == '+' ? 'success.main' : banknifty.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {banknifty.name == 'Nifty Bank' ? (banknifty.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${banknifty.pervalue < 0 ? 'error.main' : banknifty.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(banknifty.close) ? '0.00' : (banknifty.close / 100).toFixed(2)} ({isNaN(banknifty.pervalue) ? '0' : (banknifty.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* FINNIFTY */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'warning'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-f', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    FIN NIFTY
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${niftyfin.netchangeindi == '+' ? 'success.main' : niftyfin.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {niftyfin.name == 'Nifty Fin Service' ? (niftyfin.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${niftyfin.pervalue < 0 ? 'error.main' : niftyfin.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(niftyfin.close) ? '0.00' : (niftyfin.close / 100).toFixed(2)} ({isNaN(niftyfin.pervalue) ? '0' : (niftyfin.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* MIDCAP NIFTY */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'error'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-m', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    MIDCP NIFTY
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${niftymid.netchangeindi == '+' ? 'success.main' : niftymid.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {niftymid.name == 'NIFTY MID SELECT' ? (niftymid.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${niftymid.pervalue < 0 ? 'error.main' : niftymid.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(niftymid.close) ? '0.00' : (niftymid.close / 100).toFixed(2)} ({isNaN(niftymid.pervalue) ? '0' : (niftymid.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* SENSEX */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'primary'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-s', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    SENSEX
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${sensex.netchangeindi == '+' ? 'success.main' : sensex.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {sensex.name == 'SENSEX' ? (sensex.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${sensex.pervalue < 0 ? 'error.main' : sensex.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(sensex.close) ? '0.00' : (sensex.close / 100).toFixed(2)} ({isNaN(sensex.pervalue) ? '0' : (sensex.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* BANKEX */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'info'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-x', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    BANKEX
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${bankex.netchangeindi == '+' ? 'success.main' : bankex.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {bankex.name == 'BANKEX' ? (bankex.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${bankex.pervalue < 0 ? 'error.main' : bankex.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(bankex.close) ? '0.00' : (bankex.close / 100).toFixed(2)} ({isNaN(bankex.pervalue) ? '0' : (bankex.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* INDIAVIX */}
                <div className='flex items-center gap-4'>
                    <CustomAvatar skin='light' variant='rounded' color={'warning'} size={34}>
                        <i className={classnames('tabler-hexagon-letter-i', 'text-[22px]')} />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                                <Typography className='font-medium' color='text.primary'>
                                    INDIA VIX
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    className='flex justify-end is-15'
                                    color={`${indiavix.netchangeindi == '+' ? 'success.main' : indiavix.netchangeindi == '-' ? 'error.main' : ''}`}
                                >
                                    {indiavix.name == 'India VIX' ? (indiavix.ltp / 100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant='body2'
                                    className='flex justify-end is-15'
                                    color={`${indiavix.pervalue < 0 ? 'error.main' : indiavix.pervalue > 0 ? 'success.main' : ''}`}
                                >
                                    {isNaN(indiavix.close) ? '0.00' : (indiavix.close / 100).toFixed(2)} ({isNaN(indiavix.pervalue) ? '0' : (indiavix.pervalue / 100).toFixed(2)}%)
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default LiveIndices
