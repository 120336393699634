'use client'

// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import MuiTimeline from '@mui/lab/Timeline'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import Typography from '@mui/material/Typography'
import moment from 'moment'

// Components Imports
import OptionMenu from '@core/components/option-menu'
import { useEffect, useState } from 'react'
import { ACCORD_NEWAPI_URL, ACCORD_TOKEN, APIURL } from '@views/common/commonUrl'

const axios = require('axios');
const _ = require('lodash')

// Styled Timeline component
const Timeline = styled(MuiTimeline)({
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiTimelineItem-root': {
        width: '100%',
        '&:before': {
            display: 'none'
        }
    }
})

const News = ({ page }) => {

    const [newsData, setNewsData] = useState()
    useEffect(() => {
        const fetchData = async () => {
            var fromDate = moment().format('YYYY.MM.DD')
            var toDate = moment().format('YYYY.MM.DD')

            var sec = 7
            var subsec = 15
            if (page == 'equity') {
                sec = 4
                subsec = 38
            } else if (page == 'ipo') {
                sec = 1
                subsec = '1,2'
            } else if (page == 'derivatives') {
                sec = 4
                subsec = '47'
            }
            var data = {
                sec: sec,
                subsec: subsec,
                from: fromDate,
                to: toDate,
            }

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: APIURL + 'getNewsSection',
                headers: {},
                data: data
            };

            await global.axios.request(config)
                .then((response) => {
                    if (response.data) {
                        setNewsData(response.data.record)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (_.isEmpty(newsData)) {
            fetchData()
        }
    }, [])




    // sx={{ position: 'absolute', overflowY: 'scroll', height: '100%' }}
    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                avatar={<i className='tabler-list-details text-xl' />}
                title='News'
                titleTypographyProps={{ variant: 'h5' }}
                // action={<OptionMenu options={['Share timeline', 'Suggest edits', 'Report bug']} />}
                sx={{ '& .MuiCardHeader-avatar': { mr: 3 } }}
            />
            <CardContent className='flex flex-col gap-6 pbe-5' sx={{ overflow: 'auto', maxHeight: '100vh' }}>
                {newsData && newsData.length > 0 ? <Timeline>
                    {newsData.map(function (value) {
                        return (<TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot color='primary' />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <div className='flex flex-wrap items-center justify-between gap-x-2 mbe-2.5'>
                                    <Typography className='font-medium' color='text.primary'>
                                        {value.Heading}
                                    </Typography>
                                    <Typography variant='caption'>{moment(value.NewsTime, 'D/M/YYYY H:m:ss A').format("HH:mm:ss A")}</Typography>
                                </div>
                                <Typography className='mbe-2'>{value.SectionName + " - " + value.SubSectionName}</Typography>
                            </TimelineContent>
                        </TimelineItem>)
                    })}
                </Timeline>
                    :
                    <Typography align='center'>No Data Found!</Typography>
                }
            </CardContent>
        </Card>
    )
}

export default News
