import { Card, CardContent, CardHeader, CircularProgress, MenuItem, Select, Stack, Typography } from "@mui/material"
// Style Imports
import styles from '@core/styles/table.module.css'
import { useEffect, useState } from "react"

import { ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'
import moment from "moment";

const axios = require('axios');
const _ = require('lodash')

const IpoNewListing = () => {
    const [display, setDisplay] = useState('IPO_GET_CLOSEDISSUE')

    const [isLoader, setIsLoader] = useState(false)
    const [ipoListingData, setIpoListingData] = useState()
    useEffect(() => {
        if (_.isEmpty(ipoListingData)) {
            fetchData('IPO_GET_CLOSEDISSUE')
        }
    }, [])

    const fetchData = async (type) => {
        setIsLoader(true)
        setDisplay(type)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: APIURL + 'getNewIPOListing',
            headers: {}
        };

        await global.axios.request(config)
            .then(async (response) => {
                var finalArr = []
                if (response.data) {
                    setIpoListingData(response.data.record)
                }
                setIsLoader(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={"IPO NEW LISTING"}

            />
            <CardContent className="ipo">
                <table width='100%' className={styles.table}>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Date</th>
                            <th style={{ textAlign: 'right' }}>List Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader == false ? ipoListingData && ipoListingData.map((item, index) => (
                            <tr>
                                <td>{item.title}</td>
                                <td>{item.date}</td>
                                <td style={{ textAlign: 'right' }}>{item.price}</td>
                            </tr>
                        )) : <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <CircularProgress />
                            <Typography variant='caption' component='div'>Please wait...</Typography>
                        </Stack>}
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
}

export default IpoNewListing
