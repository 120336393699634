import { Grid } from "@mui/material"

import Nifty from '@views/main/Nifty'
import Sensex from '@views/main/Sensex'
import Gainer from '@views/main/Gainer'
import Looser from '@views/main/Looser'
import Volume from '@views/main/Volume'
import Value from '@views/main/Value'
import News from '@views/main/News'

const Equity = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Nifty />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Sensex />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Gainer />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Volume />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Value />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Looser />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
                <News page='equity' />
            </Grid>
        </Grid>

    )
}

export default Equity
