'use client'

// Next Imports
import dynamic from 'next/dynamic'

// MUI Imports
import Card from '@mui/material/Card'
import { useColorScheme, useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// Util Imports
import { rgbaToHex } from '@/utils/rgbaToHex'
import { Divider, Grid, LinearProgress, Typography } from '@mui/material'

// Style Imports
import styles from '@core/styles/table.module.css'

import '@/views/main/style.css'
import { useEffect, useState } from 'react'

import { ACCORD_TOKEN, APIURL } from '@/views/common/commonUrl'
import moment from 'moment'

const axios = require('axios');
const _ = require('lodash')

// Styled Component Imports
const AppReactApexCharts = dynamic(() => import('@/libs/styles/AppReactApexCharts'))

const FiiDiiActivity = ({ serverMode }) => {
    // Hooks
    const theme = useTheme()
    const { mode } = useColorScheme()

    // Vars
    const _mode = (mode === 'system' ? serverMode : mode) || serverMode
    const divider = rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.12)`)
    const disabledText = rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.4)`)

    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false }
        },
        colors: ['#00cfe8'],
        dataLabels: { enabled: true },
        plotOptions: {
            bar: {
                borderRadius: 8,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                barHeight: '30%',
                horizontal: true
            }
        },
        grid: {
            borderColor: divider,
            xaxis: {
                lines: { show: false }
            },
            padding: {
                top: -10
            }
        },
        yaxis: {
            labels: {
                style: { colors: disabledText, fontSize: '13px' }
            }
        },
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { color: divider },
            categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23'],
            labels: {
                style: { colors: disabledText, fontSize: '13px' }
            }
        }
    }

    const data = [
        {
            trend: '+$1,678',
            status: 'verified',
            cardType: 'Credit',
            cardNumber: '*4230',
            imgName: 'visa',
            date: `17 Mar ${new Date().getFullYear()}`
        },
        {
            trend: '-$839',
            status: 'rejected',
            cardType: 'Credit',
            cardNumber: '*5578',
            imgName: 'mastercard',
            date: `12 Feb ${new Date().getFullYear()}`
        },
        {
            trend: '+$435',
            cardType: 'ATM',
            status: 'verified',
            cardNumber: '*4567',
            imgName: 'american-express',
            date: `28 Feb ${new Date().getFullYear()}`
        },
        {
            trend: '+$2,345',
            status: 'pending',
            cardType: 'Credit',
            cardNumber: '*5699',
            imgName: 'visa',
            date: `08 Jan ${new Date().getFullYear()}`
        },
        {
            trend: '+$1,758',
            status: 'rejected',
            cardType: 'Credit',
            cardNumber: '*2451',
            imgName: 'visa',
            date: `19 Oct ${new Date().getFullYear()}`
        }
    ]

    const [fiiData, setFiiData] = useState()
    useEffect(() => {
        const fetchData = async () => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: APIURL + 'getDerivatives',
                headers: {}
            };

            await global.axios.request(config)
                .then((response) => {
                    if (response.data) {
                        setFiiData(response.data.record)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (_.isEmpty(fiiData)) {
            fetchData()
        }
    }, [])

    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title='FII/DII Activity'
                // subheader='$74,382.72'
                sx={{
                    flexDirection: ['column', 'row'],
                    alignItems: ['flex-start', 'center'],
                    '& .MuiCardHeader-action': { mb: 0 },
                    '& .MuiCardHeader-content': { mb: [2, 0] }
                }}
                action={
                    fiiData && <Typography variant='subtitle2'>As On {moment(fiiData.DATE, 'M/D/YYYY HH:mm:ss A').format("DD-MM-YYYY")}</Typography>
                }
            />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12 / 4}>
                        <Typography className='font-medium' color='text.primary'>
                            FII Cash Market
                        </Typography>
                    </Grid>
                    <Grid item xs={12 / 4} className='centerBorder'>
                        {/* <div className="lightRed" style={{ width: '50%' }}></div> */}
                    </Grid>
                    <Grid item xs={12 / 4} sx={{ paddingLeft: '3px' }}>
                        <div className="lightGreen" style={{ width: '100%' }}></div>
                    </Grid>
                    <Grid item xs={12 / 4}>
                        <Typography
                            color={'success.main'}
                            align='right'
                        >
                            100.00
                        </Typography>
                    </Grid>

                    <Grid item xs={12 / 4}>
                        <Typography className='font-medium' color='text.primary'>
                            DII Cash Market
                        </Typography>
                    </Grid>
                    <Grid item xs={12 / 4} className='centerBorder'>
                        <div className="lightRed" style={{ width: '50%' }}></div>
                    </Grid>
                    <Grid item xs={12 / 4} sx={{ paddingLeft: '3px' }}>
                        {/* <div className="lightGreen" style={{ width: '100%' }}></div> */}
                    </Grid>
                    <Grid item xs={12 / 4}>
                        <Typography
                            color={'error.main'}
                            align='right'
                        >
                            100.00
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Grid container spacing={1} sx={{ mt: 6 }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>FII STATISTICS</Typography>
                        <div className='overflow-x-auto fii'>
                            {fiiData && <table width='100%' className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Buy</th>
                                        <th>Sell</th>
                                        <th>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Index Futures</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.IF_BUY_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.IF_SELL_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{(fiiData.IF_BUY_VAL - fiiData.IF_SELL_VAL).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Index Options</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.IO_BUY_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.IO_SELL_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{(fiiData.IF_BUY_VAL - fiiData.IO_SELL_VAL).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Futures</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.SF_BUY_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.SF_SELL_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{(fiiData.IF_BUY_VAL - fiiData.SF_SELL_VAL).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Options</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.SO_BUY_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{fiiData.SO_SELL_VAL}</td>
                                        <td style={{ textAlign: 'right' }}>{(fiiData.IF_BUY_VAL - fiiData.SO_SELL_VAL).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{ textAlign: 'right' }}>{(parseFloat(fiiData.IF_BUY_VAL) + parseFloat(fiiData.IO_BUY_VAL) + parseFloat(fiiData.SF_BUY_VAL) + parseFloat(fiiData.SO_BUY_VAL)).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{(parseFloat(fiiData.IF_SELL_VAL) + parseFloat(fiiData.IO_SELL_VAL) + parseFloat(fiiData.SF_SELL_VAL) + parseFloat(fiiData.SO_SELL_VAL)).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{((parseFloat(fiiData.IF_BUY_VAL) + parseFloat(fiiData.IO_BUY_VAL) + parseFloat(fiiData.SF_BUY_VAL) + parseFloat(fiiData.SO_BUY_VAL)).toFixed(2) - (parseFloat(fiiData.IF_SELL_VAL) + parseFloat(fiiData.IO_SELL_VAL) + parseFloat(fiiData.SF_SELL_VAL) + parseFloat(fiiData.SO_SELL_VAL))).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FiiDiiActivity
