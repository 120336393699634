import { Card, CardContent, CardHeader } from "@mui/material"
// Style Imports
import styles from '@core/styles/table.module.css'

const IpoAnalysis = () => {
    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={"IPO ANALYSIS"}
            />
            <CardContent>
                <table width='100%' className={styles.table}>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Issue Open</th>
                            <th>Issue Close</th>
                            <th>Issue Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ABC Ltd</td>
                            <td>2022-01-01</td>
                            <td>2022-01-05</td>
                            <td>₹100</td>
                            <td>Apply</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
}

export default IpoAnalysis
