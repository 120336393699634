import { Card, CardContent, CardHeader, MenuItem, Select } from "@mui/material"
// Style Imports
import styles from '@core/styles/table.module.css'

const IpoLimelight = () => {
    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={"IPO'S LIMELIGHT"}
                action={
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={'10'}
                        // label="Age"
                        // onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem value={10}>Top</MenuItem>
                        <MenuItem value={20}>Bottom</MenuItem>
                        <MenuItem value={20}>All</MenuItem>
                    </Select>
                }
            />
            <CardContent>
                <table width='100%' className={styles.table}>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Date</th>
                            <th>List Price</th>
                            <th>LTP</th>
                            <th>Offer Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ABC Ltd</td>
                            <td>2022-01-01</td>
                            <td>2022-01-05</td>
                            <td>₹100</td>
                            <td>₹100</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
}

export default IpoLimelight
